.navbar {
  background-color: #333;
  height: 80px;
  display: flex;
  justify-content: space-between; /* Change from flex-start to space-between */
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    transparent 100%
  );
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.navbar-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  justify-content: center;
}

.menu-bars {
  margin-left: 0.5rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #4b4a4a;
  width: 250px;
  height: 80vh;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 1000;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
}

.nav-menu-items {
  width: 100%;
  position: relative;
  min-height: 100vh;
  display: flex; /* Add this line */
  flex-direction: column; /* Add this line */
  justify-content: space-between; /* Add this line */
}

.navbar-toggle {
  background-color: #4b4a4a;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.auth-modal {
  display: flex;
  position: fixed;
  width: 250px;
  top: 1%;
  background: transparent;
  z-index: 1000;
  right: -100%;
  transition: 850ms;
}

.auth-modal.show {
  right: 10px;
  transition: 350ms;
}

.nav-menu {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    transparent 100%
  );
  backdrop-filter: blur(5px);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 1000;
  margin-top: 80px;
}

.nav-menu.active {
  left: 0;
  top: 0;
  transition: 350ms;
}

.auth-button {
  margin-right: 2rem; /* when shown, slide in to 10px from the right */
  background-color: #66666667;
}
