.container {
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 320px;
  width: 250px;
  margin-top: 10px;
  background-color: rgba(238, 238, 238, 0.116);
  backdrop-filter: blur(10px);
}

.input-box {
  margin: 10px;
  width: 200px;
}

.input-box-bottom {
  margin: 10px;
  width: 200px;
  margin-bottom: 20px;
}

.center-div {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.inline-div {
  margin-right: 10px;
  display: inline-block;
}

.button-spacing {
  margin-left: 10px;
}

.full-width-button {
  width: 200px;
  margin: 5px;
}

.logout-button {
  margin-top: 20px;
}
