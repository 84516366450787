.calendar-container {
  display: flex;
  justify-content: center;
  min-height: 360px;
  margin-top: auto; /* Add this line */
}

.react-calendar {
  width: 400px; /* Adjust this to the size you want */

  max-width: 92%;
  background: transparent;

  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 10px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: 0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  align-items: center;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 70px;

  margin-bottom: 0em;
  align-items: center;
}

.react-calendar__navigation button {
  background: none;
  display: flex;
  min-height: auto;
  font-size: 15px;
  text-align: center;
  box-sizing: border-box;
}

.react-calendar__navigation button:disabled {
  background-color: #a7a7a7;
  color: #ffffff;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
  color: #ffffff;
  align-items: center;
  height: 60px;
  justify-content: center;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.2em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #131225;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.3em;
  font-size: 0.9em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #d40f0f;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  color: #ffffff;
}

.react-calendar__tile--now {
  background: #1ba001;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #132d4781;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #3d83c9bd;
}

.react-calendar__tile--active {
  background: #131225;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #0e3964;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #ca1111;
}
