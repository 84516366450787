.App {
  text-align: center;
  margin-top: 80px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #d61212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.isRunning-App-header {
  background-color: #325e2c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[contentEditable] {
  cursor: text;
  min-height: 1.2em;
  padding: 3px;
}

[contentEditable]::selection {
  background-color: rgba(255, 255, 255, 0.99);
  color: #333;
}

[contentEditable]::-moz-selection {
  background-color: rgba(255, 255, 255, 0.99);
  color: #333;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(to bottom, #131225, #444950);
  background-attachment: fixed;
  color: #ccc;
  font-family: Arial, sans-serif;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 0;
  padding-bottom: 0px;
}

isRunning-body {
  background-color: #1db46e;
  color: #ccc;
  font-family: Arial, sans-serif;
  padding-left: 5rem;
  padding-right: 5rem;
}

h1 {
  text-align: center;
  margin-bottom: 1.5rem;
}

form {
  display: flex;
  justify-content: center;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

input[type="text"] {
  padding: 0.5rem;
  font-size: 1rem;
  flex-grow: 1;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #66666667;
  color: #ccc;
}

.textera-box {
  padding: 0.5rem;
  font-size: 1rem;
  flex-grow: 1;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #66666667;
  color: #ccc;
}

.styles {
  bmBurderButton {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-left: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #444950;
  color: #ccc;
  cursor: pointer;
}

.ToDoList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
  align-content: space-around;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] {
  margin-right: 1rem;
  margin-left: 0.5rem;
}

span {
  flex-grow: 1;
}

.react-icons {
  font-size: 2rem;
  margin-bottom: 0px;
  margin-top: 4px;
  size: 10px;
}

.hidden-timer-input:-moz-focusring {
  outline: none;
}

.hidden-timer-input:focus {
  outline: none;
  text-align: right;
  box-shadow: 0 0 0 2px #000000; /* Change the color here */
}

.hidden-timer-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  outline: none;
  box-shadow: 0 0 0 2px #000000;
}

.timer-input {
  width: 1.2rem;
  padding: 0rem;
  max-width: 1.1rem;
  margin: 0 0.1rem;
  background-color: transparent;
  border: none; /* Remove border */
  color: transparent;
  font-size: 1rem;
  text-align: center;
  outline: none; /* Remove outline */
  moz-appearance: textfield;
}

.register-input {
  width: 100%;
  padding: 0.5rem;
  background-color: transparent;
  border-color: #666;
  color: inherit;
  font-size: 1rem;
  text-align: center;
  border: 1px solid #666;
  -moz-appearance: textfield;
  border-radius: 5px; /* Add rounded corners */
  margin-bottom: 1rem; /* Add space between inputs */
}

/*const timerDisplayStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1.2rem',
  margin: '0 0.5rem',
  marginRight: '0.03rem',
  marginLeft: '.4rem',
};
*/
.timer-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timer-input:focus {
  border-color: #666; /* Add this line */
}

.cycle-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.cycle-input-field {
  margin: 0rem;
  width: 2.2rem;
  max-width: 2.2rem;
  height: 2.1rem;
  max-height: 2.1rem;
  font-size: 1rem;
  text-align: center;
  border-width: 1px;
  border-color: transparent;
  background-color: transparent;
  color: #ccc;
  -moz-appearance: textfield;
  padding: 0rem;
  outline: none;
  transition: transform 0.2s ease; /* Add this for smooth transition */
}

.cycle-input-field::-webkit-outer-spin-button,
.cycle-input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cycle-input-field:focus {
  border-color: #666;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background-color: #444;
  outline: none;
}

.cycle-container {
  display: flex;
  flex-direction: column;
  margin: 0rem;
}

.cycle-change-btn {
  padding: 0.25rem 0.5rem;
  margin-right: 0rem;
  font-size: 0.6rem;
  border: none;
  border-radius: 4px;
  background-color: #66666667;
  color: #ccc;
  cursor: pointer;
}

.cycle-change-btn-plus {
  border-bottom: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
}

.cycle-change-btn-minus {
  border-radius: 0 0 4px 4px;
}

.timer-btn-container {
  display: flex;
  flex-direction: column;
  margin: 0rem;
}

.timer-change-btn {
  padding: 0.25rem 0.5rem;
  font-size: 11px;
  border: none;
  border-radius: 4px;
  background-color: #66666667;
  color: #ccc;
  cursor: pointer;
}

.timer-change-btn-plus {
  border-bottom: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
}

.timer-change-btn-minus {
  border-radius: 0 0 4px 4px;
  padding: 0.25rem 0.5rem;
}

.strike-through {
  text-decoration: line-through;
}

.checkbox {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff00;
}

.isRunning-checkbox {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff00;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 23px;
  padding-right: 20;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.isRunning-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 23px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

.isRunning-checkbox-container-notPrimary {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 23px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox {
  background-color: #131225;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox {
  background-color: #131225;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.99);

  opacity: 0;
}

.checkbox-container input:checked ~ .checkbox:after {
  display: block;
  color: #ff7272;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox:after {
  left: 6px;
  top: -1px;
  width: 6px;
  height: 16px;
  border: solid #fafafa;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 1;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.isRunning-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.isRunning-checkbox-container-notPrimary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-container .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #5c5c5c;
  border-radius: 5px;
  border: 2px solid #3a3a3a;
  opacity: 1;
}
/* Create a custom checkbox */
.isRunning-checkbox-container-notPrimary .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #008cff;
  border-radius: 5px;
  border: 2px solid #2e32fc;
  opacity: 1;
}

/* Create a custom checkbox */
.isRunning-checkbox-container .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #2fc405;
  border-radius: 5px;
  border: 2px solid #227f08;
  opacity: 1;
}

.isRunning {
  padding: 0.5rem;
  min-width: 37rem;

  margin: 0 0 0.5rem 0;
  background: #35dd02;
  color: #227f08;
  border-radius: 5px;
  border: 1px solid #000000;
}

.isRunningNotPrimary {
  padding: 0.5rem;
  min-width: 37rem;

  margin: 0 0 0.5rem 0;
  background: #386cfa;
  color: #dddddd;
  border-radius: 5px;
  border: 1px solid #000000;
}

.task {
  display: flex;
  min-width: 37rem;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  background: rgba(68, 68, 68, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(5px);
  border-radius: 5px;
  border: 1px solid rgba(68, 68, 68, 1);
}

.isRunning-isEditingTask {
  padding: 0.5rem;
  min-width: 47rem;

  margin: 0 0 0.5rem 0;
  background: #35dd02;
  color: #227f08;
  border-radius: 5px;
  border: 1px solid #666;
  box-sizing: border-box;
}

.task-isEditingTask {
  display: flex;
  min-width: 37rem;
  padding: 5px;
  margin: 0 0 0.5rem 0;
  background: #4444441a; /* Semi-transparent background */
  backdrop-filter: blur(5px);
  border-radius: 5px;
  border: 1px solid #444444;
}

.isRunning-button {
  background-color: #76f952;
  color: #227f08;
  border: 1px solid #2fc405;
  text-align: center;
  margin-left: 1rem;
  width: 5rem;
}

.isRunning-button-notPrimary {
  background-color: #19dbf5;
  color: #ffffff;

  text-align: center;
  margin-left: 1rem;
  width: 5rem;
}

.notRunning-button {
  background-color: #66666667;
  color: #c0c0c0;
  border-style: hidden;
  border-color: #000000;
  text-align: center;
  margin-left: 1rem;
  width: 5rem;
  display: block;
}

.isRunningCountdown {
  width: 65px;
  textalign: center;
  margin-right: 1rem;
  color: #227f08 !important;
  background-color: #76f952 !important;
  border: 3px #000000 !important;
  border-radius: 5px !important;
}

.isRunningCountdown-notPrimary {
  width: 65px;
  textalign: center;
  margin-right: 1rem;
  color: white !important;
  background-color: #19dbf5 !important;
  border: 3px #000000 !important;
  border-radius: 5px !important;
}

.countdown {
  width: 65px;
  text-align: center;
  margin-right: 1.5rem;
  z-index: 2000;
  border: none;
}

.countdownRun {
  width: 40px;
  textalign: center;
  margin-right: 1.5rem;
  border: 3px #000000 !important;
}

.editButtons {
}

/*

className={ isRunning ? 'isRunning' : 'getItemStyle'}

className={ isRunning ? 'isRunning-button' : 'timer-change-btn'}

*/

.isRunning-timer-input {
  width: 1.2rem;
  padding: 0rem;
  max-width: 1.1rem;
  margin: 0 0.1rem;
  background-color: #abf296;
  border: none; /* Remove border */
  color: #227f08;
  font-size: 1rem;
  text-align: center;
  outline: none; /* Remove outline */
  moz-appearance: textfield;
}

.isRunning-timer-input-notPrimary {
  width: 1.2rem;
  padding: 0rem;
  max-width: 1.1rem;
  margin: 0 0.1rem;
  background-color: #0041f3;
  border: none; /* Remove border */
  color: #0e0707;
  font-size: 1rem;
  text-align: center;
  outline: none; /* Remove outline */
  moz-appearance: textfield;
}

/*const timerDisplayStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1.2rem',
  margin: '0 0.5rem',
  marginRight: '0.03rem',
  marginLeft: '.4rem',
};
*/

.isRunning-timer-input:focus {
  border-color: #666; /* Add this line */
}

.isRunning-cycle-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  color: #227f08;
}

.isRunning-cycle-input-field {
  margin: 0rem;
  width: 2.2rem;
  max-width: 2.2rem;
  height: 2.1rem;
  max-height: 2.1rem;
  font-size: 1rem;
  text-align: center;
  border-width: 1px;
  border-color: transparent;
  background-color: transparent;
  color: #227f08;
  -moz-appearance: textfield;
  padding: 0rem;
  outline: none;
  transition: transform 0.2s ease; /* Add this for smooth transition */
}

.isRunning-cycle-input-field::-webkit-outer-spin-button,
.isRunning-cycle-input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.isRunning-cycle-input-field:focus {
  border-color: #666;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background-color: #444;
  outline: none;
}

.isRunning-cycle-btn-container {
  display: flex;
  flex-direction: column;
  margin: 0rem;
}

.isRunning-cycle-change-btn {
  padding: 0.25rem 0.5rem;
  margin-right: 0rem;
  font-size: 0.6rem;
  border: none;
  border-radius: 4px;
  background-color: #666;
  color: #ccc;
  cursor: pointer;
}

.isRunning-cycle-change-btn-plus {
  border-bottom: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
}

.isRunning-cycle-change-btn-minus {
  border-radius: 0 0 4px 4px;
}

.isRunning-timer-btn-container {
  display: flex;
  flex-direction: column;
  margin: 0rem;
}

.isRunning-timer-change-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  border: none;
  border-radius: 4px;
  background-color: #abf296;
  color: #227f08;
  cursor: pointer;
}

.isRunning-timer-change-btn-plus {
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  border-radius: 4px;
  background-color: #abf296;
  color: #227f08;
  border-bottom: 1px solid #227f08;
  border-radius: 4px 4px 0 0 !important;
  cursor: pointer;
  border: 1px solid #227f08;
}

.isRunning-timer-change-btn-notPrimary {
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  border: none;
  border-radius: 4px;
  background-color: #abf296;
  color: #227f08;
  cursor: pointer;
}

.isRunning-timer-change-btn-plus-notPrimary {
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  border-radius: 4px;
  background-color: #19dbf5;
  color: #020497;
  border-bottom: 1px solid #020497;
  border-radius: 4px 4px 0 0 !important;
  cursor: pointer;
  border: 1px solid #020497;
}

.isRunning-timer-change-btn-minus {
  border-radius: 0 0 4px 4px !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  border-radius: 4px;
  background-color: #abf296;
  color: #227f08;
  border-bottom: 1px solid #227f08;
  cursor: pointer;
  border: 1px solid #227f08;
}

.isRunning-timer-change-btn-minus-notPrimary {
  border-radius: 0 0 4px 4px !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  border-radius: 4px;
  background-color: #19dbf5;
  color: #020497;
  border-bottom: 1px solid #020497;
  cursor: pointer;
  border: 1px solid #020497;
}

.number-input {
  width: 1.2rem;
  padding: 0rem;
  max-width: 1.1rem;
  margin: 0 0.1rem;
  background-color: transparent;
  border: none; /* Remove border */
  color: transparent;
  font-size: 1rem;
  text-align: center;
  outline: none; /* Remove outline */
  moz-appearance: textfield;
}

@media (max-width: 768px) {
  .ToDoList {
    align-items: center;
  }

  .task {
    min-width: 100%;
  }

  .notRunning-button {
    background-color: #66666667;
    color: #c0c0c0;
    border-style: hidden;
    border-color: #000000;
    text-align: center; /* centers text horizontally */
    font-size: small;
    margin-left: 1rem;
    width: auto;
    display: flex; /* enables flexbox layout */
    justify-content: center; /* centers content horizontally in flexbox */
    align-items: center; /* centers content vertically in flexbox */
    height: 45px; /* specify a height to ensure the text is centered vertically */
  }
}
